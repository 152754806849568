import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../../components/layout/Layout'
import SectionCard from '../../components/layout/SectionCard'
import { StaticImage } from 'gatsby-plugin-image'

import forestImgPath from '../../assets/images/regenerate/fabrizio-conti-pineforest-unsplash.jpg'

let helmut = {
  title: 'Treeland Trails Diversify',
  description: [
    'diverisfied enterprises, landscape, watershed, forestry',
    'ranching, criollo cattle, spanish goats',
    'guest experiences, mountain biking, weddings, events',
    'wood products, cabin kits',
  ].join('\n'),
  keywords: [''].join('\n'),
}

const staticImages = {
  forest: {
    staticImg: (
      <StaticImage
        src="../../assets/images/diversify/NorthCedarGulchRidge600x450.jpg"
        alt="forest"
        layout="constrained"
        placeholder="blurred"
        width={600}
      />
    ),
  },
  ranching: {
    staticImg: (
      <StaticImage
        src="../../assets/images/diversify/Cows_20230606_1600x450.jpg"
        alt="forest"
        layout="constrained"
        placeholder="blurred"
        width={600}
      />
    ),
  },
  guest: {
    staticImg: (
      <StaticImage
        src="../../assets/images/diversify/mtbike600x424.png"
        alt="forest"
        layout="constrained"
        placeholder="blurred"
        width={600}
      />
    ),
  },
  product: {
    staticImg: (
      <StaticImage
        src="../../assets/images/diversify/thomas-peham-8RapT49-eqI-unsplash-600x400.jpg"
        alt="forest"
        layout="constrained"
        placeholder="blurred"
        width={600}
      />
    ),
  },
}

const DiversifyIndex = () => {
  return (
    <Layout
      helmutTitle={helmut.title}
      helmutDescription={helmut.description}
      helmutKeywords={helmut.keywords}
    >
      <div id="diversify" className="page-content">
        <div id="intro">
          <div className="inner">
            <header className="">
              <h1>Diversify</h1>
            </header>
            To sustainably support our mission, we need diversified holistic
            solutions that compliment the strengths and renewable resources of
            the land. We have several teams and projects that are just getting
            off the ground!
            <p />
          </div>
        </div>
      </div>

      <SectionCard
        staticImg={staticImages['forest'].staticImg}
        sectionStyle="floatLeftLarge"
      >
        <h2>Landscape and Forestry</h2>
        <p>
          {' '}
          Our Landscape and Forestry Team has an ambitious charter. There are
          forest thinning, brush clearing, and oak habitat improvement projects
          in motion, funded by grants. (Thank You California Forestry
          Improvement Program and US Fish & Wildlife!)
        </p>
        <p>
          In 2021, we embarked on a biochar tree-planting experiment to see how
          using biochar could increase the viability of young saplings during
          the punishingly hot drought. More recently, we've reseeded over-grazed
          lands to organically crowd out invasive star thistle, to provide
          better forage for our animals, and to improve the soil.
        </p>
        <p>
          And we have road building and reservoir projects next in the queue. We
          plan to first focus on the small projects with big gains, for
          instance, capturing as much water runoff as we can during the winter
          wet season{' '}
        </p>
      </SectionCard>

      <SectionCard
        staticImg={staticImages['ranching'].staticImg}
        sectionStyle="floatLeftLarge"
      >
        <h2>Ranching</h2>
        <p>
          {' '}
          We believe that well-managed animal impact is a critical component of
          regenerative agriculture. We have chosen Criollo Cattle and Spanish
          Goats for their hardiness and compatibility with our landscape forage
          opportunities. We currently have a small herd of 14 cattle, will soon
          have over 60 goats, and plan to grow the herds as we grow the team and
          gain experience. We will sell animals as replacements (for others
          building their herds) and for meat.{' '}
        </p>
      </SectionCard>

      <SectionCard
        staticImg={staticImages['guest'].staticImg}
        sectionStyle="floatLeftLarge"
      >
        <h2>Guest Experiences</h2>
        <p>
          {' '}
          There are soooo many opportunities for Guest Experiences on the land!
          One of our favorite dreams and goals is to build a Mountain Bike
          Destination with hundreds of miles of trails: there isn’t a place that
          exists like this in the world! To get started, we’ve mapped our first
          9-mile-loop trail with plans to begin building this winter.
        </p>
        <p>
          We have plans for camping/lodging, bike/sporting events, and weddings.
          We’d love to become an educational hub.{' '}
        </p>
      </SectionCard>

      <SectionCard
        staticImg={staticImages['product'].staticImg}
        sectionStyle="floatLeftLarge"
      >
        <h2>Product</h2>
        <p>
          {' '}
          One of the biggest challenges and opportunities we have is an enormous
          amount of ponderosa pine that is dying and needs to be harvested. Our
          best financial opportunity is to set up a saw mill and convert the
          logs into sellable products, such as tiny homes and log cabin kits.
          Numerous other ideas have been discussed, but this will be our first
          focus.
        </p>
      </SectionCard>
    </Layout>
  )
}

export default DiversifyIndex
